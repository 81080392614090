export const availablesMonitorings = [
  {
    id: 1,
    clickable_url: (id: string) => `/temperature/${id}`,
    field_name: "temperature",
    name: "Temperature",
    form: {
      fields: [
        {
          field_name: "degrees",
          name: "Degrès",
          type: "number",
        },
        {
          field_name: "taking",
          name: "Mode de prise",
          type: "select",
          options: [
            "Rectale",
            "Axillaire (sous l'aisselle)",
            "Auriculaire (dans l'oreille)",
            "Frontale (temporaire)",
            "Orale",
          ],
        },
      ],
    },
    getUrl: (id: string) => `/children/${id}/temperature-monitoring`,
    postUrl: (id: string) => `/children/${id}/temperature-monitoring`,
  },
  {
    id: 2,
    clickable_url: (id: string) => `/treatment/${id}`,
    field_name: "medication",
    name: "Médicaments",
    form: {
      fields: [
        {
          field_name: "medication",
          name: "Nom",
          type: "autocomplete",
        },
        {
          field_name: "dosage",
          name: "Quantité (en mg)",
          type: "number",
          // options: Array.from({ length: 20 }, (_, i) => `${(i + 1) * 50}mg`),
        },
        // {
        //   field_name: "time",
        //   name: "Heure",
        //   type: "time",
        // },
        {
          field_name: "taking",
          name: "Mode de prise",
          type: "select",
          options: [
            "Suppositoire",
            "Administration orale",
            "Gouttes ophtalmiques",
            "Gouttes auriculaires",
            "Inhalation",
            "Injection",
            "Crème",
            "Pommade",
            "Sirop",
            "Gélule",
            "Comprimé",
          ],
        },
      ],
    },
    getUrl: (id: string) => `/children/${id}/medication-monitoring`,
    postUrl: (id: string) => `/children/${id}/medication-monitoring`,
  },
  {
    id: 3,
    clickable_url: (id: string) => `/feeding/${id}`,
    field_name: "feeding",
    name: "Alimentation",
    form: {
      fields: [
        // en ml
        {
          field_name: "quantity",
          name: "Quantité",
          type: "number",
        },
        // {
        //   name: "Type",
        //   type: "select",
        //   options: ["Lait", "Solide"],
        // },
      ],
    },
    getUrl: (id: string) => `/children/${id}/feeding-monitoring`,
    postUrl: (id: string) => `/children/${id}/feeding-monitoring`,
  },
  {
    id: 4,
    clickable_url: (id: string) => `/weight/${id}`,
    field_name: "weight",
    name: "Poids",
    form: {
      fields: [
        {
          field_name: "value",
          name: "Poids",
          type: "number",
        },
      ],
    },
    getUrl: (id: string) => `/children/${id}/weight-monitoring`,
    postUrl: (id: string) => `/children/${id}/weight-monitoring`,
  },
  {
    id: 5,
    clickable_url: (id: string) => `/vomiting/${id}`,
    field_name: "vomiting",
    name: "Vomissements",
    form: {
      fields: [
        {
          field_name: "color",
          name: "Couleur",
          type: "select",
          options: [
            "Alimentaires",
            "Rouges",
            "Transparents",
            "Jaunes",
            "Marrons",
          ],
        },
        {
          field_name: "quantity",
          name: "Quantité",
          type: "select",
          options: ["Peu", "Moyen", "Beaucoup"],
        },
        {
          field_name: "description",
          name: "Description",
          type: "text",
        },
      ],
    },
    getUrl: (id: string) => `/children/${id}/vomiting-monitoring`,
    postUrl: (id: string) => `/children/${id}/vomiting-monitoring`,
  },
  {
    id: 6,
    clickable_url: (id: string) => `/poop/${id}`,
    field_name: "stool",
    name: "Selles",
    form: {
      fields: [
        {
          field_name: "consistency",
          name: "Type",
          type: "select",
          options: ["Dure", "Molle", "Liquide"],
        },
        {
          field_name: "color",
          name: "Couleur",
          type: "select",
          options: ["Jaune", "Vert", "Noir", "Rouge", "Blanc", "Marron"],
        },
        {
          field_name: "quantity",
          name: "Quantité",
          type: "select",
          options: ["Peu", "Moyen", "Beaucoup", "Déborde de la couche"],
        },
      ],
    },
    getUrl: (id: string) => `/children/${id}/stool-monitoring`,
    postUrl: (id: string) => `/children/${id}/stool-monitoring`,
  },
];
