/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../component/Table/Table";
// import Tabs from "../../component/Tabs/Tabs";
import "../NewChild/NewChild.scss";
import Carousel from "../NewChild/components/Carousel";
import { useAuth } from "../../hooks/useAuth";
import axios from "../../lib/config/axios";
import TableCheck from "../../component/TableCheck/TableCheck";
import Header from "../../component/Header";
// import TableTreatment from "../../component/Table/TableTreatment";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
import { debounce, update } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { BackToMenuButton } from "../../component/BackToMenuButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { ChildCard } from "../../component/ChildCard";
import { G } from "@react-pdf/renderer";
import { useSnackbar } from "notistack";
import { useUpdateContext } from "../../contexts/UpdateContext";
import { cp } from "fs";
import TableMedicationTreatment from "../../component/Table/TableMedicationTreatment";

type GlobalDataType = {
  firstname?: string;
  gender?: "FEMALE" | "MALE";
  birthdate?: Date;
  gestationalAgeAtBirth?: number;
  firstParentId?: number;
  image_id?: number;
  size?: number;
  newSize?: number;
  weight?: number;
  newWeight?: number;
  usualQuantity: undefined;
};

type AntecedentType = {
  id: number;
  date: string;
  name: string;
};

type AllergyType = {
  id: number;
  date: string;
  name: string;
  manifestation: string;
};

const BackgroundList = [
  { type: "Chirurgicaux", name: "Appendicite" },
  {
    type: "Chirurgicaux",
    name: "Torsion testiculaire",
  },
  {
    type: "Chirurgicaux",
    name: "Fracture (opérée)",
  },
  {
    type: "Chirurgicaux",
    name: "Amygdales / végétations",
  },
  {
    type: "Chirurgicaux",
    name: "Panaris (Ongle incarné)",
  },
  {
    type: "Chirurgicaux",
    name: "Hernie ombilicale",
  },
  {
    type: "Chirurgicaux",
    name: "Invagination intestinale opérée",
  },
  {
    type: "Chirurgicaux",
    name: "Sténose du pylore",
  },
  {
    type: "Chirurgicaux",
    name: "Volvulus intestinal",
  },
  {
    type: "Chirurgicaux",
    name: "Occlusion intestinale",
  },
  {
    type: "Chirurgicaux",
    name: "Communication inter ventriculaire CIV",
  },
  {
    type: "Chirurgicaux",
    name: "Communication inter auriculaire CIA",
  },
  {
    type: "Chirurgicaux",
    name: "Ectopie testiculaire (Testicule non descendu)",
  },
  { type: "Chirurgicaux", name: "Phimosis (opéré)" },
  { type: "Médicaux", name: "Bronchiolite" },
  { type: "Médicaux", name: "Asthme" },
  { type: "Médicaux", name: "Méningite" },
  { type: "Médicaux", name: "Varicelle" },
  { type: "Médicaux", name: "Infection urinaire" },
  { type: "Médicaux", name: "COVID" },
  { type: "Médicaux", name: "Pyélonéphrite" },
  { type: "Médicaux", name: "Constipation" },
  {
    type: "Médicaux",
    name: "Pronation douloureuse",
  },
  {
    type: "Médicaux",
    name: "Syndrome bébé secoué",
  },
  {
    type: "Médicaux",
    name: "Invagination intestinale non opérée",
  },
  {
    type: "Médicaux",
    name: "Adénolymphite mésentérique",
  },
  { type: "Médicaux", name: "TDAH" },
  { type: "Médicaux", name: "Laryngite" },
  { type: "Médicaux", name: "Otite" },
  { type: "Médicaux", name: "Angine" },
  { type: "Médicaux", name: "Scarlatine" },
  { type: "Médicaux", name: "Rougeole" },
  { type: "Médicaux", name: "Roséole" },
  { type: "Médicaux", name: "Phimosis non opéré" },
  { type: "Médicaux", name: "Diabète" },
  {
    type: "Médicaux",
    name: "Convulsion hyperthermique",
  },
  { type: "Médicaux", name: "Épilepsie" },
];

const allergiesTypeMatchingList = {
  FOOD: "Alimentaires",
  ANTIBIOTICS: "Antibiotiques",
  ANTI_INFLAMMATORY: "Anti-inflammatoires",
  OTHER: "Autre",
};

const upperCaseFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const manifestationMatchingList = [
  { id: "REDNESS", name: "Rougeur" },
  { id: "BUTTONS", name: "Boutons" },
  { id: "SWELLING", name: "Gonflement" },
  { id: "SKIN_ITCH", name: "Démangeaison" },
  { id: "RESPIRATORY_GENE", name: "Gêne respiratoire" },
  { id: "RHINORHEA", name: "Rhinite" },
  { id: "ITCHY_EYES", name: "Yeux qui piquent" },
  { id: "TEARING", name: "Larmoiement" },
  { id: "ETERNALLY", name: "Éternuements" },
  { id: "VOMITING", name: "Vomissements" },
  { id: "DIARRHEA", name: "Diarrhée" },
  { id: "OTHER", name: "Autre" },
];
interface Child {
  id: string;
  image_id: string;
  firstname: string;
}

const ChildProfil = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const [children, setChildren] = useState<Child[]>([]);
  const [activeTab, setActiveTab] = useState<string>("step1");
  const [globalData, setGlobalData] = useState<GlobalDataType>({
    firstname: "name",
    gender: "FEMALE",
    birthdate: undefined,
    gestationalAgeAtBirth: undefined,
    firstParentId: undefined,
    image_id: undefined,
    size: undefined,
    newSize: undefined,
    weight: undefined,
    newWeight: undefined,
    usualQuantity: undefined,
  });
  const [allergyList, setAllergyList] = useState<any[]>([]);
  const [medicineList, setMedicineList] = useState<any[]>([]);
  const [vaccinationsList, setVaccinationsList] = useState<any[]>([]);
  const [vacinationData, setVacinationData] = useState<any[]>([]);
  const [allergiesData, setAllergiesData] = useState<any[]>([]);
  const [antecedentsData, setAntecedentsData] = useState<AntecedentType[]>([]);
  const [size, setSize] = useState([]);
  const [weight, setWeight] = useState([]);

  const [medicinesData, setMedicinesData] = useState<any[]>([]);
  const [editingAntecedent, setEditingAntecedent] =
    useState<AntecedentType | null>(null);
  const [editingAllergy, setEditingAllergy] = useState<any | null>(null);
  const [medications, setMedications] = useState<any[]>([]);
  const [medicationName, setMedicationName] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  let profilePic = require(`../../assets/profilPic/${child.image_id}.png`);

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      try {
        const response = await axios.get(`/medicines/search?value=${query}`, {
          headers: { Authorization: `Bearer ${user}` },
        });
        setMedications(response.data);
      } catch (error) {
        console.error(error);
      }
    }, 300), // Wait for 300ms after the last input
    [user]
  );

  useEffect(() => {
    if (trigger && medicationName.length >= 3) {
      debouncedSearch(medicationName);
    }
  }, [trigger, medicationName, debouncedSearch]);
  const handleAddAntecedent = useCallback(
    (item: AntecedentType) => setAntecedentsData((prev) => [...prev, item]),
    []
  );

  const handleAddAllergy = useCallback(
    (item: AllergyType) => {
      const newAllergy = {
        date: item.date,
        name: item.name,
        manifestation: item.manifestation,
        id: item.id,
      };
      setAllergiesData([...allergiesData, newAllergy]);
    },
    [allergiesData]
  );

  // Fonction pour mettre à jour les valeurs du formulaire d'édition de l'allergie
  const handleAllergyInputChange = (
    key: keyof typeof editingAllergy,
    value: string
  ) => {
    setEditingAllergy((prev: any) => (prev ? { ...prev, [key]: value } : null));
  };

  const vacinationColumns = useMemo(() => {
    const columns = [];
    for (let i = 1; i <= 10; i++) {
      columns.push({
        key: `col${i}`,
        value: t(`new_child.form-data.vacinationColumns.col${i}`),
      });
    }
    return columns;
  }, [t]);

  const vacinationLines = useMemo(() => {
    const lines = [];
    for (let i = 1; i <= 10; i++) {
      lines.push({
        key: `line${i}`,
        value: t(`new_child.form-data.vacinationLines.line${i}`),
      });
    }
    return lines;
  }, [t]);

  const allergiesColumns = useMemo(
    () => [
      {
        header: t("mesure.date"),
        accessor: "date",
        type: "month",
        component: "input",
      },
      {
        header: t("new_child.stepper.step3.allergies"),
        accessor: "name",
        component: "select",
        type: "autocomplete",
      },
      {
        header: t("new_child.form_label.manifestation"),
        accessor: "manifestation",
        component: "select",
        type: "manifestationList",
      },
    ],
    []
  );

  const antecedentsColumns = useMemo(
    () => [
      {
        header: t("mesure.date"),
        accessor: "date",
        type: "month",
        component: "input",
      },
      {
        header: t("new_child.stepper.step2.backgrounds"),
        accessor: "name",
        component: "select",
        type: "autocomplete",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchChildData = async () => {
      try {
        const response = await axios.get(`/children/${id}`, {
          headers: { Authorization: `Bearer ${user}` },
        });
        const data = response.data;
        setGlobalData({
          firstname: data.child.firstname,
          gender: data.child.gender,
          birthdate: data.child.birthdate,
          gestationalAgeAtBirth: data.child.gestationalAgeAtBirth,
          size: data.size,
          weight: data.weight,
          usualQuantity: data.child.usualQuantity,
          image_id: data.child.image_id,
        });
        setAllergiesData(data.allergies || []);
        setAntecedentsData(data.medicalBackgrounds || []);
        setVacinationData(data.vaccinations || []);
      } catch (error) {
        console.error("Failed to fetch child data", error);
      }
    };

    fetchChildData();
  }, [id, user]);

  useEffect(() => {
    if (user === null) return;

    const fetchParentProfile = async () => {
      try {
        const response = await axios.get("/users/profile", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const parent = response.data;
        setGlobalData({ ...globalData, firstParentId: parent.id });
      } catch (err) {
        console.error(err);
      }
    };

    const fetchSizeAndWeight = async () => {
      try {
        const response = await axios.get(`/children/${id}`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const size = response.data.size.value;
        const weight = response.data.weight.value;
        setSize(size);
        setWeight(weight);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchAllergies = async () => {
      try {
        const response = await axios.get("/allergies", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const allergiesData = response.data;
        setAllergyList(allergiesData);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchVaccines = async () => {
      try {
        const response = await axios.get("/vaccinations", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const vaccineData = response.data;
        setVaccinationsList(vaccineData);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchMedications = async () => {
      try {
        const response = await axios.get("/medicines", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const medData = response.data;
        setMedicineList(medData);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchChildBackgrounds = async () => {
      try {
        const response = await axios.get(`/children/${id}/all-data`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const childBackgrounds = response.data;
        setAntecedentsData(childBackgrounds.medicalBackgrounds);
        setAllergiesData(childBackgrounds.allergies);
        setVacinationData(childBackgrounds.vaccinations);
        setMedicinesData(
          childBackgrounds.medicationTreatments.map((med: any) => ({
            ...med,
            dosageUnit: med.dosageUnit.toLowerCase(),
          }))
        );
      } catch (err) {
        console.error(err);
      }
    };
    fetchParentProfile();
    fetchSizeAndWeight();
    fetchAllergies();
    fetchVaccines();
    fetchMedications();
    fetchChildBackgrounds();
  }, [user]);

  const sortedAntecedentsData = [...antecedentsData].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB.getTime() - dateA.getTime();
  });

  const handleInputChange = (key: any, value: any) => {
    setGlobalData((prev) => ({ ...prev, [key]: value }));
  };

  const onChangeOtherData = (
    data: never,
    type: "allergy" | "antecedent" | "medicine"
  ) => {
    if (type == "allergy") {
      setAllergiesData([...allergiesData, data]);
    } else if (type == "antecedent") {
      setAntecedentsData([...antecedentsData, data]);
    } else if (type == "medicine") {
      setMedicinesData([...medicinesData, data]);
    }
  };

  const { triggerUpdate } = useUpdateContext();

  const handleSaveChanges = async () => {
    const newMedicinesData = medicinesData.map((med: any) => {
      let medecineData = { ...med };
      if (med.dosageAmount) {
        medecineData = {
          ...medecineData,
          dosageAmount: Number(med.dosageAmount),
        };
      }
      if (med.dosageUnit) {
        medecineData = {
          ...medecineData,
          dosageUnit: med.dosageUnit.toUpperCase(),
        };
      }
      if (med.interval) {
        medecineData = { ...medecineData, interval: Number(med.interval) };
      }
      if (med.dailyFrequency) {
        medecineData = {
          ...medecineData,
          dailyFrequency: Number(med.dailyFrequency),
        };
      }
      if (med.days === null) {
        medecineData = { ...medecineData, days: [] };
      }
      return medecineData;
    });

    try {
      setIsUpdating(true);
      const payload = {
        medicalBackgrounds: antecedentsData.map((item) => ({
          name: item.name,
          date: item.date,
        })),
        allergies: allergiesData.map((allergy) => ({
          allergyId: allergy.id,
          manifestation: allergy.manifestation,
          date: allergy.date,
        })),
        vaccinations: vacinationData,
        medicationTreatments: newMedicinesData,
      };

      const response = await axios.put(`/children/${id}`, payload, {
        headers: { Authorization: `Bearer ${user}` },
      });

      enqueueSnackbar("Les modifications ont été sauvegardées.", {
        variant: "success",
      });
      setGlobalData(response.data);
      profilePic = require(
        `../../assets/profilPic/${response.data.image_id}.png`
      );
      setIsEditing(false);
      triggerUpdate();
      navigate(0);
    } catch (error) {
      enqueueSnackbar("Les modifications n'ont pas été sauvegardées.", {
        variant: "error",
      });
      console.error("Error saving changes", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const onValidateForm = async (e: any) => {
    e.preventDefault();

    await axios.put(
      `/children/${id}`,
      {
        ...globalData,
        // @ts-expect-error error
        birthdate: new Date(globalData.birthdate).toISOString(),
        weight: globalData.newWeight,
        size: globalData.newSize,
      },
      {
        headers: { Authorization: `Bearer ${user}` },
      }
    );

    const newWeightObject = globalData.newWeight
      ? {
          value: globalData.newWeight,
          date: new Date().toISOString(),
        }
      : globalData.weight;

    const newSizeObject = globalData.newSize
      ? {
          value: globalData.newSize,
          date: new Date().toISOString(),
        }
      : globalData.size;

    setGlobalData({
      ...globalData,
      // @ts-expect-error error
      weight: newWeightObject,
      // @ts-expect-error error
      size: newSizeObject,
    });

    setIsEditing(false);
  };

  return (
    <div className="new-child-container">
      <Header title={child.firstname} titleColor="blue" showBackToMenu={true} />
      <section>
        <form>
          <div className="px-4">
            {isUpdating && <p>{t("Updating child information...")}</p>}
            {isEditing ? (
              // ! mise en page
              <div className="flex flex-col space-y-8 ">
                <div className="flex justify-end">
                  <button
                    onClick={() => setIsEditing(!isEditing)}
                    className="mt-4 w-1/12 px-12 justify-center justify-items-end bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
                  >
                    <span>{t("buttons.cancel")}</span>
                  </button>
                </div>
                <div className="">
                  <Carousel
                    active={globalData.image_id}
                    onChange={(idx) => handleInputChange("image_id", idx)}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center space-y-4">
                <img
                  src={profilePic}
                  alt="child"
                  className="w-40 h-40 md:w-60 md:h-60 rounded-full"
                />
                <button
                  onClick={() => setIsEditing(!isEditing)}
                  className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
                >
                  <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </span>
                  <span>{t("buttons.edit")}</span>
                </button>
              </div>
            )}
          </div>
          <h2 className="mt-6 mb-2">{t("new_child.stepper.step1")}</h2>
          <div id="step1">
            <div>
              <label>{t("new_child.form_label.firstname")}</label>
              {isEditing ? (
                <input
                  value={globalData.firstname}
                  placeholder={globalData.firstname}
                  onChange={(e) =>
                    handleInputChange("firstname", e.target.value)
                  }
                />
              ) : (
                <input
                  value={globalData.firstname}
                  placeholder={globalData.firstname}
                  disabled
                />
              )}
            </div>
            <div>
              <label>{t("new_child.form_label.genre")}</label>
              {isEditing ? (
                <select
                  value={globalData.gender}
                  onChange={(e) => handleInputChange("gender", e.target.value)}
                >
                  <option key="FEMALE" value="FEMALE">
                    {t("new_child.form_label.genres.female")}
                  </option>
                  <option key="MALE" value="MALE">
                    {t("new_child.form_label.genres.male")}
                  </option>
                </select>
              ) : (
                <input
                  disabled
                  type="text"
                  value={globalData.gender === "MALE" ? "Garçon" : "Fille"}
                  placeholder={globalData.gender}
                />
              )}
            </div>
            <div>
              <label>{t("new_child.form_label.birthdate")}</label>
              {isEditing ? (
                <input
                  type="date"
                  value={
                    new Date(globalData?.birthdate ?? new Date())
                      .toISOString()
                      .split("T")[0]
                  }
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    handleInputChange("birthdate", e.target.value);
                  }}
                />
              ) : (
                <input
                  type="text"
                  value={
                    new Date(globalData?.birthdate ?? new Date())
                      .toISOString()
                      .split("T")[0]
                  }
                  placeholder={
                    new Date(globalData?.birthdate ?? new Date())
                      .toISOString()
                      .split("T")[0]
                  }
                  disabled
                />
              )}
            </div>
            <div>
              <label>{t("new_child.form_label.size")}</label>
              {isEditing ? (
                <input
                  type="number"
                  //@ts-expect-error error number
                  placeholder={globalData.newSize}
                  onChange={(e) =>
                    handleInputChange("newSize", Number(e.target.value))
                  }
                />
              ) : (
                <input
                  type="number"
                  //@ts-expect-error error number
                  value={globalData.size?.value ?? 0}
                  //@ts-expect-error error number
                  placeholder={globalData.size?.value ?? 0}
                  disabled
                />
              )}
            </div>
            <div>
              <label>{t("new_child.form_label.weight")}</label>
              {isEditing ? (
                <input
                  type="number"
                  //@ts-expect-error error number
                  placeholder={globalData.newWeight}
                  onChange={(e) =>
                    handleInputChange("newWeight", Number(e.target.value))
                  }
                />
              ) : (
                <input
                  type="text"
                  //@ts-expect-error error number
                  value={globalData.weight?.value ?? 0}
                  //@ts-expect-error error number
                  placeholder={globalData.weight?.value ?? 0}
                  disabled
                />
              )}
            </div>
            <div>
              <label>{t("new_child.form_label.gestation_time")}</label>
              {isEditing ? (
                <input
                  type="number"
                  placeholder={globalData.gestationalAgeAtBirth?.toString()}
                  onChange={(e) =>
                    handleInputChange(
                      "gestationalAgeAtBirth",
                      Number(e.target.value)
                    )
                  }
                />
              ) : (
                <input
                  type="number"
                  value={globalData.gestationalAgeAtBirth}
                  placeholder={globalData.gestationalAgeAtBirth?.toString()}
                  disabled
                />
              )}
            </div>
            <div>
              <label>{t("new_child.form_label.usual_quantity")}</label>
              {isEditing ? (
                <select
                  value={globalData.usualQuantity || ""}
                  onChange={(e) =>
                    handleInputChange("usualQuantity", Number(e.target.value))
                  }
                >
                  <option value="" disabled>
                    {t("new_child.form_label.select_quantity")}
                  </option>
                  {[30, 60, 90, 120, 150, 180, 210, 230, 260].map(
                    (quantity) => (
                      <option key={quantity} value={quantity}>
                        {quantity}
                      </option>
                    )
                  )}
                </select>
              ) : (
                <input
                  type="number"
                  value={globalData.usualQuantity}
                  placeholder={globalData.usualQuantity}
                  disabled
                />
              )}
            </div>
            {isEditing && (
              <div className="w-full flex flex-row justify-end text-right mt-8">
                <button
                  type="submit"
                  onClick={onValidateForm}
                  className="mt-4 w-40 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
                >
                  {/* <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </span> */}
                  <span>Sauvegarder</span>
                </button>
              </div>
            )}
          </div>
          <div id="step2">
            <Table
              columns={antecedentsColumns}
              data={sortedAntecedentsData}
              title={t("new_child.stepper.step2.title")}
              add={(item: AntecedentType) => handleAddAntecedent(item)}
              suggestionsList={BackgroundList}
              remove={(updatedData: AntecedentType[]) =>
                setAntecedentsData(updatedData)
              }
              onSave={handleSaveChanges}
            />
          </div>
          <div id="step3">
            <Table
              columns={allergiesColumns}
              data={allergiesData}
              title={t("new_child.stepper.step3.title")}
              add={(data: any) => handleAddAllergy(data)}
              suggestionsList={allergyList.map((allergy) => ({
                ...allergy,
                name: upperCaseFirstLetter(allergy.name),
                // @ts-expect-error error
                type: allergiesTypeMatchingList[allergy.type],
              }))}
              manifestationList={manifestationMatchingList}
              remove={(data: any[]) => setAllergiesData(data)}
              onSave={handleSaveChanges}
            />
          </div>
          <div id="step4">
            <h2>{t("new_child.stepper.step4")}</h2>
            <TableCheck
              tableList={vaccinationsList}
              data={vacinationData}
              setData={setVacinationData}
              columns={vacinationColumns}
              lines={vacinationLines}
              columnsName={{
                key: "age",
                title: "",
              }}
            />
          </div>
          <div id="step5">
            <TableMedicationTreatment
              title={t("new_child.stepper.step5")}
              data={medicinesData}
              medicationName={medicationName}
              add={(data: never) => onChangeOtherData(data, "medicine")}
              remove={(data: any[]) => setMedicinesData(data)}
              setTrigger={setTrigger}
              setMedicationName={setMedicationName}
              suggestionsList={medications}
              onSave={handleSaveChanges}
            />
          </div>
        </form>
      </section>
    </div>
  );
};

export default ChildProfil;
