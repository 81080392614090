/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { ButtonIcon } from "../../component/Button";
// import MedIcon from "../../assets/icons/medication-icon.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CardPharmacy } from "../../component/CardPharmacy";
import axios from "../../lib/config/axios";
import { useAuth } from "../../hooks/useAuth";
import { enqueueSnackbar, useSnackbar } from "notistack";
import Header from "../../component/Header";
import Box from "../../assets/Medications/Pharmacy.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import InfoAlert from "../../component/InfoAlert";
import { Autocomplete, TextField } from "@mui/material";
import { set } from "lodash";

interface MedicationItem {
  cip: string;
  name: string;
  datetimeAdd: string;
  datetimePeremption: string;
  numberOfBox: number;
  id: string;
}

const MedicationModal = ({
  setOpen,
  setMedications,
  setMedicationData,
  user,
  cip,
  name,
  id,
}: any) => {
    const { enqueueSnackbar } = useSnackbar();
    const [form, setForm] = useState<any>({ cip, name, id });

    const handleSubmit = async (e: any) => {
        console.log("heheh", form.datetimePeremption);
        e.preventDefault();
        try {
            const response = await axios.post(
                `/pharmacy-boxes/medicines`,
                {
                    medicineId: form.id,
                    quantity: Number(form.numberOfBox),
                    expiryDate: new Date(form.datetimePeremption),
                },
                {
                    headers: {
                        Authorization: `Bearer ${user}`,
                    },
                }
            );

            if (response.status === 201) {
                setOpen(false);
                setMedications((prevState: any) => [
                    ...prevState,
                    response.data,
                ]);
                setMedicationData((prevState: any) => [
                    ...prevState,
                    response.data,
                ]);
                enqueueSnackbar("Médicament ajouté avec succès", {
                    autoHideDuration: 2000,
                    variant: "success",
                });
                window.location.reload();
            } else {
                enqueueSnackbar("Une erreur s'est produite", {
                    autoHideDuration: 2000,
                    variant: "error",
                });
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Échec de l'ajout du médicament", {
                autoHideDuration: 2000,
                variant: "error",
            });
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
            <div className="relative bg-white w-2/3 md:w-1/2 xl:w-1/3 p-8 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold text-blue-600 mb-4">
                    Ajouter un médicament
                </h2>
                <button
                    className="absolute top-8 right-8 "
                    onClick={() => setOpen(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                <form
                    className="flex flex-col mt-4 gap-4"
                    onSubmit={handleSubmit}
                >
                    <div className="flex flex-col gap-2">
                        <label htmlFor="cip">CIP</label>
                        <input
                            value={form.cip || ""}
                            onChange={(e) =>
                                setForm({ ...form, cip: e.target.value })
                            }
                            type="text"
                            id="cip"
                            name="cip"
                            className="border border-gray-300 rounded-md p-2"
                            disabled
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label htmlFor="name">Nom</label>
                        <input
                            value={form.name || ""}
                            onChange={(e) =>
                                setForm({ ...form, name: e.target.value })
                            }
                            type="text"
                            id="name"
                            name="name"
                            className="border border-gray-300 rounded-md p-2"
                            disabled
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label htmlFor="datetimePeremption">
                            Date de péremption
                        </label>
                        <input
                            value={form.datetimePeremption || ""}
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    datetimePeremption: e.target.value,
                                })
                            }
                            type="date"
                            id="datetimePeremption"
                            name="datetimePeremption"
                            className="border border-gray-300 rounded-md p-2"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label htmlFor="numberOfBox">Nombre de boîtes</label>
                        <input
                            value={form.numberOfBox || ""}
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    numberOfBox: e.target.value,
                                })
                            }
                            type="number"
                            id="numberOfBox"
                            name="numberOfBox"
                            className="border border-gray-300 rounded-md p-2"
                        />
                    </div>
                    <button
                        onClick={() => setOpen(true)}
                        type="submit"
                        className="mt-4 w-2/5 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
                    >
                        <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                            <FontAwesomeIcon
                                icon={faPlus}
                                style={{ color: "#ffffff" }}
                            />
                        </span>
                        <span>Ajouter</span>
                    </button>
                </form>
            </div>
        </div>
    );
};

export const PharmacyBox = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
    const [medications, setMedications] = useState<MedicationItem[]>([]);
    const [cip, setCip] = useState("");
    const [pharmacyBox, setPharmacyBox] = useState<any>();
    const [cipToPass, setCipToPass] = useState("");
    const [nameToPass, setNameToPass] = useState("");
    const [idToPass, setIdToPass] = useState(0);
    const [medicationData, setMedicationData] = useState<MedicationItem[]>([]);
    const [selectedMedication, setSelectedMedication] = useState<any>();

    const { user } = useAuth();
    // const [monitoringDate, setMonitoringDate] = useState(new Date());
    const [open, setOpen] = useState(false);

    const handleOpenModal = (cip: string, name: string, id: number) => {
        setCipToPass(cip); // Stocke le CIP sélectionné
        setNameToPass(name);
        setIdToPass(id);
        setOpen(true); // Ouvre la modale
    };

    useEffect(() => {
        const fetchMedications = async () => {
            try {
                const response = await axios.get(`/pharmacy-boxes/medicines`, {
                    headers: {
                        Authorization: `Bearer ${user}`,
                    },
                });
                setPharmacyBox(response.data.medicines);
                console.log("boite", response.data.medicines);
            } catch (error) {
                console.error(error);
            }
        };

        fetchMedications();
    }, [user]);

    useEffect(() => {
        if (cip.length < 3) {
            return;
        }

        axios
            .get(`/medicines/search?value=${cip}`, {
                headers: {
                    Authorization: `Bearer ${user}`,
                },
            })
            .then((res) => {
                setMedications(res.data);
                setNameToPass(res.data[0].name);
                setIdToPass(res.data[0].id);
            })
            .catch((err) => console.error(err));
    }, [cip]);

    const handleDeleteCard = async (id: string | undefined) => {
        if (!id) return;
        try {
            // ! l'url marche pas
            await axios.delete(`/pharmacy-boxes/medicines/${id}`, {
                headers: {
                    Authorization: `Bearer ${user}`,
                },
            });
            console.log("pharmacie b ==", pharmacyBox);
            setMedicationData((prevCards: any[]) => [
                ...prevCards.filter((card) => card.id !== id),
            ]);
            console.log("medicationData", medicationData);
            console.log("pharmacie b ==", pharmacyBox);
            enqueueSnackbar("Médicament supprimé avec succès", {
                autoHideDuration: 2000,
                variant: "success",
            });
        } catch (error) {
            console.error("Failed to delete the medication:", error);
            enqueueSnackbar("Échec de la suppression du médicament", {
                autoHideDuration: 2000,
                variant: "error",
            });
        }
    };

    return (
        <div className="w-full flex flex-col items-center justify-center">
            {open && (
                <MedicationModal
                    setOpen={setOpen}
                    setMedications={setMedications}
                    setMedicationData={setMedicationData}
                    user={user}
                    cip={cipToPass}
                    name={nameToPass}
                    id={idToPass}
                />
            )}
            <div className="flex flex-col justify-center w-full">
                <Header
                    title={t("pharmacy_box.title")}
                    titleColor="blue"
                    showBackToMenu={true}
                />
                <section className="md:w-1/2 w-full flex flex-col px-6 justify-center self-center">
                    <div>
                        <div className="flex justify-center my-6">
                            <ButtonIcon
                                text={t("children.pharmacy_box")}
                                color="blue-300"
                                urlRedirection="/pharmacy_box"
                                icon={Box}
                                width="80"
                                disabled={false}
                            />
                        </div>
                        <InfoAlert
                            message={
                                <>
                                    {t("pharmacy_box.info")},{" "}
                                    <a
                                        className="underline hover:text-blue-500"
                                        href="https://ansm.sante.fr/documents/reference/codification-et-tracabilite-des-medicaments"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        cliquez ici
                                    </a>
                                </>
                            }
                        />
                        <div className="mt-8 flex flex-row space-x-4">
                            <Autocomplete
                                options={medications ?? []}
                                getOptionLabel={(option) =>
                                    `(${option.cipCode}) - ${option.name}`
                                }
                                fullWidth
                                value={selectedMedication}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setSelectedMedication(newValue);
                                }}
                                className="bg-white border-2 border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-2 focus:ring-blue-400 focus:border-blue-400 w-full"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // label="Search"
                                        placeholder={t("pharmacy_box.code")}
                                        value={cip}
                                        onChange={(event) => {
                                            setCip(event.target.value);
                                        }}
                                    />
                                )}
                            />
                            <button
                                onClick={() => {
                                    handleOpenModal(cip, nameToPass, idToPass);
                                    console.log(cip);
                                    console.log("TO PASSE PUTE", nameToPass);
                                }}
                                type="button"
                                className=" bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
                            >
                                <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        style={{ color: "#ffffff" }}
                                    />
                                </span>
                                <span>{t("buttons.add")}</span>
                            </button>
                        </div>
                    </div>
                </section>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-20 gap-x-8 px-6 gap-y-6">
                    {pharmacyBox?.map(
                        (
                            m: {
                                medicine: {
                                    name: string;
                                    cipCode: string;
                                };
                                expiryDate: string;
                                quantity: number;
                                id: string;
                            },
                            index: React.Key | null | undefined
                        ) => (
                            <div
                                className="flex flex-row items-center justify-center"
                                key={index}
                            >
                                <CardPharmacy
                                    time={new Date(
                                        m.expiryDate
                                    ).toLocaleDateString()}
                                    isWarningOut={
                                        new Date(m.expiryDate) <= new Date()
                                    }
                                    isWarningComming={
                                        new Date(m.expiryDate) <=
                                        new Date(
                                            new Date().setDate(
                                                new Date().getDate() + 7
                                            )
                                        )
                                    }
                                    name={m.medicine.name}
                                    cip={m.medicine.cipCode}
                                    boxe={m.quantity.toString()}
                                    onDelete={() => handleDeleteCard(m.id)}
                                />
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );

};