/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CardMonitoring } from "../component/Cards";
import axios from "../lib/config/axios";
import { useAuth } from "../hooks/useAuth";
import Header from "../component/Header";
import InfoAlert from "../component/InfoAlert";
// import FindPharmacy from "../component/FindPharmacy";
import Look from "../assets/icons/loupe-green.png";
import { ButtonIcon } from "../component/Button";
import TableMedicationTreatment from "../component/Table/TableMedicationTreatment";
import { debounce } from "lodash";
import "./Consultation.scss";
import { useSnackbar } from "notistack";

interface Pathology {
  id: number;
  name: string;
  description?: string;
}

interface ChildPathologies {
  id: number;
  pathologyId: number;
  date: string;
  notes: string;
  startDate: string;
  endDate: string;
}

export const Consultation = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const navigate = useNavigate();

  const [form, setForm] = useState({
    date: "",
    pathology: "",
    comment: "",
  });
  const [pathologiesList, setPathologiesList] = useState<Pathology[]>([]);
  const [childPathologiesList, setChildPathologies] = useState<
    ChildPathologies[]
  >([]);
  const [medicinesData, setMedicinesData] = useState<any[]>([]);
  const [medicationName, setMedicationName] = useState("");
  const [medications, setMedications] = useState<any[]>([]);
  const [trigger, setTrigger] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      try {
        const response = await axios.get(`/medicines/search?value=${query}`, {
          headers: { Authorization: `Bearer ${user}` },
        });
        setMedications(response.data);
      } catch (error) {
        console.error(error);
      }
    }, 300), // Wait for 300ms after the last input
    [user]
  );

  useEffect(() => {
    if (trigger && medicationName.length >= 3) {
      debouncedSearch(medicationName);
    }
  }, [trigger, medicationName, debouncedSearch]);

  useEffect(() => {
    const fetchData = async () => {
      const pathologiesResponse = await axios.get("/pathologies", {
        headers: { Authorization: `Bearer ${user}` },
      });
      setPathologiesList(
        // @ts-expect-error - Sort by id
        pathologiesResponse.data.sort((a, b) => a.name.localeCompare(b.name))
      );

      const consultationsResponse = await axios.get(
        `/children/${child.id}/consultations`,
        {
          headers: { Authorization: `Bearer ${user}` },
        }
      );
      setChildPathologies(consultationsResponse.data);
    };

    fetchData();
  }, [child.id, user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newMedicinesData = medicinesData.map((med: any) => {
      let medecineData = { ...med };
      if (med.dosageAmount) {
        medecineData = {
          ...medecineData,
          dosageAmount: Number(med.dosageAmount),
        };
      }
      if (med.dosageUnit) {
        medecineData = {
          ...medecineData,
          dosageUnit: med.dosageUnit.toUpperCase(),
        };
      }
      if (med.interval) {
        medecineData = { ...medecineData, interval: Number(med.interval) };
      }
      if (med.dailyFrequency) {
        medecineData = {
          ...medecineData,
          dailyFrequency: Number(med.dailyFrequency),
        };
      }
      if (med.days === null) {
        medecineData = { ...medecineData, days: [] };
      }
      return medecineData;
    });

    const selectedPathology = pathologiesList.find(
      (p) => p.name === form.pathology
    );

    if (!selectedPathology) {
      console.error("Pathology not selected properly.");
      return;
    }

    try {
      await axios.post(
        `/children/${child.id}/consultations`,
        {
          consultations: [
            {
              pathologyId: selectedPathology.id,
              date: form.date,
              notes: form.comment,
              medicationTreatments: newMedicinesData,
            },
          ],
        },
        {
          headers: { Authorization: `Bearer ${user}` },
        }
      );
      setForm({ date: "", pathology: "", comment: "" });
      setMedicinesData([]);
      enqueueSnackbar(t("consultation.success"), {
        autoHideDuration: 3000,
        variant: "success",
      });
      navigate(0);
    } catch (error) {
      console.error("Error adding consultation:", error);
    }
  };

  return (
    <div className="w-full">
      <Header
        title={t("titles.consultation")}
        titleColor="green"
        childInfo={{
          picture: child.image_id,
          name: child.firstname,
          urlRedirection: `/child-profile/${child.id}`,
        }}
        showBackToMenu
      />

      <section className="flex flex-col px-6 lg:px-12 w-full">
        <form className="flex flex-col justify-center" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 my-8">
            <div className="flex flex-col mb-6">
              <label className="mb-2 font-semibold text-gray-700">
                {t("mesure.date")}
              </label>
              <input
                type="date"
                value={form.date}
                onChange={(e) => setForm({ ...form, date: e.target.value })}
                className="bg-white border-2 border-emerald-500 text-gray-900 text-sm rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 w-full p-3"
                required
              />
            </div>
            <div className="flex flex-col mb-6">
              <label className="mb-2 font-semibold text-gray-700">
                {t("consultation.table_header.scikness")}
              </label>
              <select
                value={form.pathology}
                onChange={(e) =>
                  setForm({ ...form, pathology: e.target.value })
                }
                className="bg-white border-2 border-emerald-500 text-gray-900 text-sm rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 w-full p-3"
                required
              >
                <option value="" disabled>
                  Sélectionnez une maladie
                </option>
                {pathologiesList.map((pathology) => (
                  <option key={pathology.id} value={pathology.name}>
                    {pathology.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col mb-6">
              <label className="mb-2 font-semibold text-gray-700">
                {t("consultation.table_header.comment")}
              </label>
              <input
                type="text"
                value={form.comment}
                onChange={(e) => setForm({ ...form, comment: e.target.value })}
                className="bg-white border-2 border-emerald-500 text-gray-900 text-sm rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 w-full p-3"
              />
            </div>
            <div className="flex flex-col mb-6"></div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="treatment-section">
              <TableMedicationTreatment
                title={t("consultation.table_header.prescription")}
                data={medicinesData}
                medicationName={medicationName}
                add={(data: never) =>
                  setMedicinesData([...medicinesData, data])
                }
                remove={(data: any[]) => setMedicinesData(data)}
                setTrigger={setTrigger}
                setMedicationName={setMedicationName}
                suggestionsList={medications}
                withDates
              />
            </div>
            <button
              type="submit"
              className="py-2 px-6 bg-green-600 text-white rounded-lg font-semibold shadow-md hover:bg-green-700 flex items-center focus:outline-none focus:ring-2 focus:ring-green-300 my-6"
            >
              <span className="mr-2">Sauvegarder</span>
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </button>
          </div>
        </form>

        <div className="history-section mt-8">
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">
            {t("titles.history")}
          </h2>
          <InfoAlert message={t("titles.pop_no_recent_consultation")} />
          {childPathologiesList.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {childPathologiesList.map((c, index) => (
                <CardMonitoring
                  key={index}
                  time={c.date}
                  icone="consultation"
                  title={
                    pathologiesList.find((p) => p.id === c.pathologyId)?.name ||
                    ""
                  }
                  description={c.notes}
                  isWarning={false}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center text-center text-gray-500 mt-8">
              <p className="text-lg md:text-xl">
                {t("titles.no_recent_consultation")}
              </p>
            </div>
          )}
        </div>

        <div className="fixed bottom-0 right-0 m-5">
          <ButtonIcon
            text="Trouver une pharmacie"
            color="green-500"
            urlRedirection="https://pharmacie-de-garde.org/"
            icon={Look}
            width="300px"
            disabled={false}
          />
        </div>
      </section>
    </div>
  );
};
